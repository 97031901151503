$headings-font-family: 'Lato', serif;
$font-family-base: 'Questrial', sans-serif;
$font-size-base: 1.2rem;

$bg-purple: rgba(82, 82, 158, 1);
// $bg-purple: #18477e;

$fg-purple: rgba(155, 155, 215, 1);
// $fg-purple: #5581b4;

$primary: $bg-purple;

$light-grey: #ccc;

$border-radius-lg: 0.6rem;

// $card-border-width: $border-width;
$card-border-color: $bg-purple;
$card-border-radius: $border-radius-lg;
// $card-cap-padding-y: $card-spacer-y * .5;
// $card-cap-padding-x: $card-spacer-x;
$card-cap-bg: $bg-purple;
$card-cap-color: white;
// $card-color: null;
// $card-bg: $white;

$link-color: $bg-purple;
// Importing bootstrap completely, so we don't need to re-import from anywhere else
// boostrap scss kept in a separate directory from scss so it isn't converted to css
@import "bootstrap";

// Keeping the fonts local
$font-path: "/src/fonts" !default;
$img-path: "/src/img" !default;

@font-face {
  font-family: 'Niconne';
  src: url("#{$font-path}/Niconne-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Questrial';
  src: url("#{$font-path}/Questrial-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body,
.blocks-background {
  background-image: url("#{$img-path}/rays.webp");
  background-size: cover;
}

header {
  margin-top: 2rem;

  .logo {
    font-family: Niconne, serif !important;
    font-size: 3rem;
  }

  .nav {
    font-size: 0.8rem;

    // padding: 0.5rem;
    border-radius: $border-radius-lg;
    background-color: $bg-purple;
    border: 1px solid $fg-purple;
    color: white;
    // line-height: normal;

    .nav-item {
      margin: auto;

      a {
        color: white;

        &:hover,
        &:active {
          color: gray;
        }
      }
    }
  }
}

#pricing {
  @extend .mt-5;
  @extend .pt-5;
}

.pricing-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
}

.container.tagline-container {
  height: 85vh;

  .tagline {
    height: 70vh;
  }
}

.parallax {
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  .parallax__background_img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.parallax__layer--base {
  transform: translateZ(0);
}

.parallax__layer--back {
  transform: translateZ(-0.9px) scale(2);
}

.parallax__layer--icon-a {
  transform: translateZ(-3px) scale(4);
}

.parallax__layer--icon-b {
  transform: translateZ(-2.2px) scale(3.2);
}

.parallax__layer--icon-c {
  transform: translateZ(-1.5px) scale(2.5);
}

.services-list {
  a.circle {
    height: 6rem;
    width: 6rem;
    background-color: $bg-purple;
    border-radius: 50%;
    display: inline-block;

    &:hover,
    &:active {
      background-color: $fg-purple;
    }

    &.active {
      background-color: $fg-purple;
    }

    img {
      width: 80%;
      height: 80%;
      margin: 10%;
    }
  }

  .tab-pane {
    background-color: $light-grey;
    border-radius: $border-radius-lg;
  }
}

.text-and-image-block {
  .container {
    @extend .rounded-3;

    .textandimageblock-image {
      width: 100%;
    }
  }

  // Rounded corners on image top
  .image-block {
    img {
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }
  }

  @include media-breakpoint-up(lg) {
    // rounded corners on outer image
    .image-block {
      img {
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $border-radius-lg;
      }
    }

    &:nth-child(odd) {
      .text-block {
        @extend .order-lg-1;
      }

      .image-block {
        @extend .order-lg-2;

        // rounded corners on outer image
        img {
          border-top-left-radius: 0;
          border-top-right-radius: $border-radius-lg;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: $border-radius-lg;
        }
      }
    }
  }
}

.form-wrapper {
  border-radius: $border-radius-lg;
}
